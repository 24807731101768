/* src/App.css */
.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.App-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
